<template>
  <b-card>
    <form-component :discount-code="discountCode" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/discount-codes/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, edit } from '@/http/sales/discount-codes';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      discountCode: {
        id: null,
        code: null,
        value: null,
        email: null,
        type: {
          value: null,
          name: null,
        },
        amount: 0,
        expired_date: null,
        is_active: false,
      },
    };
  },

  async created() {
    await this.fetchDiscountCode();
  },

  methods: {
    async fetchDiscountCode() {
      try {
        const { data } = await show(this.$route.params.id);

        this.discountCode = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania kodu rabatowego. Skontaktuj się ze swoim developerem.',
        );
      }
    },

    async onSubmit(data) {
      try {
        await edit(data.id, data);

        this.showSuccessNotification('Dane zapisane', 'Kod rabatowy został pomyślnie zaktualizowany.');
        this.$router.push({ name: 'discount-codes-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas aktualizacji kodu rabatowego. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
